@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.App {
  color: #EDAA29;
  background-color: #EDAA29;
  width : 100%;
  max-height: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0px;
  overflow-y: hidden;
}

.html_container{
  background-color: #EDAA29;
  max-height: 100%;
  height: 100%;
  width: 93%;
  padding-left: 7% !important;
  left: 0;
  top: 0;
  margin-top: 1% !important;
  position: absolute;
  flex: auto;
  overflow-y: hidden;

}

.terminal_content{
  max-height: inherit;
  z-index: 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  overflow: hidden;
  position: relative;
  border-radius: 10px ;
  height: 65%; 
  width: 60%;
  border-radius: 0px 0px 25px 25px ;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 0px !important;
}

.terminal{
  padding: 0px !important;
}

.content{
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.folder_present{
  max-height: inherit;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 10px ;
  height: 40%;
  max-height: 40%;
  width: 100%;
  top: 30%;
  left: -10%;
  z-index: 1;
  object-fit: contain;
  position: relative;
  border-radius: 0px 0px 25px 25px ;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  background: #EDAA29;
  color : black;
  box-sizing: content-box;
  overflow-y: hidden;
}

.header{
  display: flex;
  flex-direction: row;
  flex-wrap: auto;
  width: 100%;
  height: 60px;
  left: 1%;
  top: 8%;
  position: relative;
  margin-bottom: 8%;
  color: inherit;

}

.linkedin_logo{
  width: 35px;
  height: 35px;
  top: 70%;
  left: 10%;
  position:relative;
}


.github_logo{
  width: 35px;
  height: 35px;
  top: 65%;
  left: 10%;
  position:relative;
  
}

.sidebar{
  width: 20%;
  min-height:100%;
  height: 100%;
  justify-content: center;
}

.push_to_end{
  height: 10px;
  margin-bottom: 200px;
  min-height: 90% !important;
}

.menu_bar{
  display: flex;
  margin-left: 10%;
}


.bar_text{
  display: flex;
  padding-left: 10%;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  font-style: 'Poppins' sans-serif;
  font-weight: 400;
  line-height: 50%;
  color: #000000 !important;
  padding-right: 50px!important;
  padding-left: 50px!important;
}

.bar_text_about{
  top: 5%;
  font-style: normal;
  font-weight: normal;
  font-style: 'Poppins' sans-serif;
  font-weight: 400;
  font-size : 30px;
  text-align: center;
  line-height: 50%;
  color: #000000;
  
}
a{
  color: black;
}

.logoContainer{
  top: 5%;
  left: 5%;
  position: relative;
}

.logo{
  top: 0;
  left: 0%;
  width : 30%;
  height: 50%;
  position: relative;
  align-items: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
